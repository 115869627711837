import { getFormProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { type SetStateAction, useState } from 'react'
import { useFetcher } from 'react-router'
import { ErrorList, Field } from '#app/components/forms.tsx'
import { Badge } from '#app/components/ui/badge.tsx'
import { Button } from '#app/components/ui/button'
import {
	Card,
	CardHeader,
	CardFooter,
	CardContent,
	CardTitle,
	CardDescription,
} from '#app/components/ui/card'
import { StatusButton } from '#app/components/ui/status-button.tsx'
import { formatHours, useIsPending } from '#app/utils/misc.tsx'
import { backgroundColor } from '#app/utils/user-schedule.ts'
import { UserScheduleEditorSchema } from '#app/utils/user-validation.ts'
import { useOptionalAdminUser, useOptionalUser } from '#app/utils/user.ts'
import { type action } from '../index.server'

export function UserScheduleViewer({
	user,
	userSchedule,
}: {
	user: {
		display: string | null
	}
	userSchedule: {
		port: {
			ditch: number
		}
		first: boolean | null
		crossover: boolean | null
		last: boolean | null
		hours: number | null
	}
}) {
	return (
		<Card key={userSchedule.port.ditch}>
			<CardHeader>
				<CardTitle>Ditch {userSchedule.port.ditch}</CardTitle>
				<CardDescription>{user.display}</CardDescription>
			</CardHeader>
			<CardDescription className="mx-3 mb-0 mt-1.5 rounded-sm border-2 border-blue-900 p-2 text-center text-blue-700">
				{userSchedule.hours
					? `You are signed up for ${formatHours(userSchedule.hours)}.`
					: 'You have not signed up for hours.'}
			</CardDescription>
			<CardContent>
				<div className="flex flex-col">
					<ChargesPills charges={userSchedule} />
					<Field
						className="md:w-[250px]"
						labelProps={{ children: 'Hours' }}
						inputProps={{
							name: 'hours',
							type: 'number',
							step: 0.5,
							min: 0,
							max: 12,
							readOnly: true,
							defaultValue: userSchedule.hours || '',
						}}
					/>
				</div>
			</CardContent>
		</Card>
	)
}

export function UserScheduleEditor({
	user,
	schedule,
	userSchedule,
	previous,
}: {
	user: {
		id: string
		display: string | null
		defaultHours: number
		restricted: boolean | null
		restriction: string | null
	}
	schedule: {
		id: string
		source: string
	}
	userSchedule: {
		port: {
			id: string
			ditch: number
		}
		first: boolean | null
		crossover: boolean | null
		last: boolean | null
		hours: number | null
	}
	previous?: number | null
}) {
	const scheduleEditor = useFetcher<typeof action>()
	const isPending = useIsPending()
	const currentUser = useOptionalUser()
	const userIsAdmin = useOptionalAdminUser()
	const canEdit =
		(user.restricted !== true && user.id === currentUser?.id) || userIsAdmin

	const handlePrevious = (): void => {
		if (previous) setHoursValue(previous)
	}
	const handleDefault = (): void => {
		if (user.defaultHours) setHoursValue(user.defaultHours)
	}
	const handleHoursChanged = (e: {
		target: { value: SetStateAction<string> }
	}) => {
		const { value } = e.target
		if (Number(value) >= 0) setHoursValue(Number(value))
	}

	const [hoursValue, setHoursValue] = useState(userSchedule.hours)

	const [form, fields] = useForm({
		id: 'signup-form',
		constraint: getZodConstraint(UserScheduleEditorSchema),
		lastResult: scheduleEditor.data?.result,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: UserScheduleEditorSchema })
		},
		shouldRevalidate: 'onBlur',
	})

	return (
		<Card key={userSchedule.port.ditch}>
			<scheduleEditor.Form method="POST" {...getFormProps(form)}>
				<input
					type="hidden"
					name="intent"
					value="upsert-user-schedule-action"
				/>
				<input type="hidden" name="userId" value={user.id} />
				<input type="hidden" name="scheduleId" value={schedule.id} />
				<input type="hidden" name="portId" value={userSchedule.port.id} />
				<input type="hidden" name="ditch" value={userSchedule.port.ditch} />
				<CardHeader>
					<CardTitle>Ditch {userSchedule.port.ditch}</CardTitle>
					<CardDescription>{user.display}</CardDescription>
				</CardHeader>
				<CardDescription className="mx-3 mb-0 mt-1.5 rounded-sm border-2 border-blue-900 p-2 text-center text-blue-700">
					{userSchedule.hours
						? `You are signed up for ${formatHours(userSchedule.hours)}.`
						: 'You have not signed up for hours.'}
				</CardDescription>
				<CardContent>
					<div className="flex flex-col">
						<ChargesPills charges={userSchedule} />
						<Field
							className="md:w-[250px]"
							labelProps={{ children: 'Hours' }}
							inputProps={{
								name: 'hours',
								type: 'number',
								step: 0.5,
								min: 0,
								max: 12,
								value: hoursValue || '',
								onChange: handleHoursChanged,
							}}
							errors={fields.hours.errors}
						/>
						<ErrorList errors={form.errors} id={form.errorId} />
					</div>
				</CardContent>
				{canEdit ? (
					<CardFooter className="flex items-center justify-between gap-2 px-2 pb-2">
						<div className="flex gap-1">
							{user.defaultHours > 0 ? (
								<Button
									variant="secondary"
									type="reset"
									onClick={handleDefault}
								>
									Default
								</Button>
							) : null}
							{previous ? (
								<Button
									variant="secondary"
									type="reset"
									onClick={handlePrevious}
								>
									Previous
								</Button>
							) : null}
						</div>
						<StatusButton
							type="submit"
							disabled={isPending}
							status={isPending ? 'pending' : 'idle'}
						>
							Submit
						</StatusButton>
					</CardFooter>
				) : null}
			</scheduleEditor.Form>
		</Card>
	)
}

function ChargesPills({
	charges: { first, crossover, last },
}: {
	charges: {
		first: boolean | null
		crossover: boolean | null
		last: boolean | null
	}
}) {
	return (
		<div id="charges-pills" className="flex w-full flex-row justify-end">
			{first && (
				<Badge
					className={`ml-1 capitalize ${backgroundColor('first')}`}
					variant="outline"
				>
					{'First'}
				</Badge>
			)}
			{crossover && (
				<Badge
					className={`ml-1 capitalize ${backgroundColor('crossover')}`}
					variant="outline"
				>
					{'Crossover'}
				</Badge>
			)}
			{last && (
				<Badge
					className={`ml-1 capitalize ${backgroundColor('last')}`}
					variant="outline"
				>
					{'Last'}
				</Badge>
			)}
		</div>
	)
}
